<template>
  <table v-if="item !== null">
    <tr v-for="(value, key) in item" :key="key">
      <td>{{ key }}</td>
      <td>{{ value }}</td>
    </tr>
  </table>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      default: function() {
        return {};
      }
    }
  }
};
</script>
